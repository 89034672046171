import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-games-dropdown',
  templateUrl: './games-dropdown.component.html',
  styleUrls: [
    '../../../app.component.scss',
    './games-dropdown.component.scss'
  ]
})
export class GamesDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
