import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: [
    '../../app.component.scss',
    './site-footer.component.scss'
  ]
})
export class SiteFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
