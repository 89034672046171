<nav class="navbar navbar-expand-lg navbar-dark background-slate-stone">
    <a class="navbar-brand" [routerLink]="['home']">Dark Dungeon Studio (Pty) Ltd</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['home']">HOME</a>
        </li>
        <app-games-dropdown></app-games-dropdown>
        <app-tools-dropdown></app-tools-dropdown>
        <!-- <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['work']">WORK</a>
        </li> -->
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['about']">ABOUT</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['contact']">CONTACT</a>
        </li>
      </ul>
      <!-- <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-secondary my-2 my-sm-0" type="submit">Search</button>
      </form> -->
    </div>
  </nav>