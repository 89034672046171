<!-- Footer -->
<footer class="page-footer font-small blue pt-4">

    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">
  
      <!-- Grid row -->
      <div class="row">
  
        <!-- Grid column -->
        <div class="col-md-6 mt-md-0 mt-3">
  
          <!-- Content -->
          <h5 class="text-uppercase">Delve deeper</h5>
          <p>
            Creating any game is a journey of its own.
            If you would like to join us on our adventure, please follow us on our social media channels. 
            We welcome feedback from the community and appreciate every message from you.  <br> <br>
            Let's shape the future of gaming together!
          </p>
          <div class="social_icons">
            <div class="row mt-3">
              <a href="https://www.facebook.com/DarkDungeonStudio/" target="_blank" class="social_icon col-md-4">
                <img class="social-icon" src="../../../assets/icons/facebook.png" alt="Facebook">
                <span class="social_label">
                  Facebook
                </span>
              </a>
              <a href="https://twitter.com/DarkDungeonStdo" target="_blank" class="social_icon col-md-4">
                <img class="social-icon" src="../../../assets/icons/twitter.png" alt="Twitter">
                <span class="social_label">
                  Twitter
                </span>
              </a>
              <a href="https://www.youtube.com/channel/UCkFrSLPKpN2ro_TklTerWEg" target="_blank" class="social_icon col-md-4">
                <img class="social-icon" src="../../../assets/icons/youtube.png" alt="YouTube">
                <span class="social_label">
                  YouTube
                </span>
              </a>
            </div>
            <div class="row mt-3">              
              <a href="https://www.instagram.com/darkdungeonstudio/" target="_blank" class="social_icon col-md-4">
                <img class="social-icon" src="../../../assets/icons/instagram.png" alt="Instagram">
                <span class="social_label">
                  Instagram
                </span>
              </a>
              <a href="https://darkdungeonstudio.itch.io/" target="_blank" class="social_icon col-md-4">
                <img class="social-icon" src="../../../assets/icons/itch.png" alt="itch.io">
                <span class="social_label">
                  itch.io
                </span>
              </a>
              <a href="https://discord.gg/9RukBXQdtb" target="_blank" class="social_icon col-md-4">
                <img class="social-icon" src="../../../assets/icons/discord.png" alt="Discord">
                <span class="social_label">
                  Discord
                </span>
              </a>
            </div>
          </div>
  
        </div>
        <!-- Grid column -->
  
        <hr class="clearfix w-100 d-md-none pb-3">
        
        <div class="vl divider"></div>
  
        <!-- Grid column -->
        <!-- <div class="col-md-3 mb-md-0 mb-3">
  
          <h5 class="text-uppercase">GAMES</h5>
  
          <ul class="list-unstyled">
            <li>
              <a [routerLink]="['games/the-bug-hunt']">The Bug Hunt</a>
            </li>
            <li>
              <a [routerLink]="['games/desolve']">DeSolve</a>
            </li>
            <li>
              <a [routerLink]="['games/cube-next-generation']">CUBE - Next Generation</a>
            </li>
          </ul>
  
        </div> -->
        <!-- Grid column -->
  
        <!-- Grid column -->
        <!-- <div class="col-md-3 mb-md-0 mb-3">
  
          <h5 class="text-uppercase">TABLETOP</h5>
  
          <ul class="list-unstyled">
            <li>
              <a [routerLink]="['tabletop/curse-of-caer-loc']">Curse of Caer Loc (Genesys)</a>
            </li>
          </ul>
  
        </div> -->
        <!-- Grid column -->
  
      </div>
      <!-- Grid row -->
  
    </div>
    <!-- Footer Links -->
  
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3 mt-3">
        Copyrights © <a [routerLink]="['home']"> Dark Dungeon Studio</a>. 
        All rights reserved. All company names, brand names, trademarks and logos are the property of their respective owners. 
        <p>
          <a [routerLink]="['privacy-policy']">Click here for our privacy policy.</a>
        </p>
    </div>
    <!-- Copyright -->
  
  </footer>
  <!-- Footer -->