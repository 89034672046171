<div class="row">
    <div class="col-md-8 offset-md-2">
        <div class="d-flex justify-content-center">
            <img src="../../../assets/images/Dungeon Logo transparent.png" alt="Dark Dungeon Studio" class="banner-image">
        </div>
    </div>
</div>
<div class="textPage">
    <div class="row info-box">
        <div class="col-md-12 section text-center">
            <div class="p-3">
                <h4><I>THE DARKEST OF DUNGEONS HOLD THE BRIGHTEST OF TREASURES!</I></h4>
            </div>
        </div>
        <div class="col-md-12 section">
            <div class="p-3">
                <!--Left column-->
                <h2>Who are we?</h2>
                <p>
                    Welcome to Dark Dungeon Studio, where gaming meets epic challenges and endless possibilities! <br>
                    We're a passionate team just starting our journey, dedicated to crafting the toughest and most competitive video games out there. <br>
                    Our mission? To push your skills to the limit, fuel your competitive spirit, and open doors to new opportunities in the e-sports world.  <br>
                    Although we’re just beginning and don’t have a product yet, we're excited for the adventure ahead and can’t wait to share our vision with you.  <br>
                    Join us on this thrilling journey, and together, let's redefine the future of gaming!
                </p>
            </div>
        </div>
        <!-- <div class="col-md-6 section">
            <div class="p-3">
            </div>            
        </div> -->
    </div>
</div>