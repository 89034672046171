<div class="textPage">
    <div class="row info-box">
        <div class="col-md-12 section">
            <div class="p-3">
                <h2>CONTACT US</h2>
                <h3>Contact Details</h3>                
                <div class="row">
                    <div class="col-md-2">
                        <b>Email</b>:
                    </div>
                    <div class="col-md-10">
                        <a href="mailto:info@darkdungeonstudio.com"> info@darkdungeonstudio.com</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row info-box">
        <div class="col-md-12 section">
            <hr>
            <div class="p-3">
                <h3>Address</h3>
                <p>
                    South Africa
                </p>
            </div>
        </div>
    </div>
</div>