import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductModel } from 'src/app/models/product.model';

@Component({
  selector: 'app-product-template',
  templateUrl: './product-template.component.html',
  styleUrls: [
    '../../app.component.scss',
    './product-template.component.scss'
  ]
})
export class ProductTemplateComponent implements OnInit {
  products:ProductModel[] = [
    {
      id: 'directory-database-search-engine',
      name: 'Directory Database Search Engine',
      description: "This tool enables you to search through files and folders that exist outside of your Unity project, and import them directly from the utility window.\r\n\r\nThis is extremely useful if you have a lot of raw assets like:\r\n- Music\r\n- Sound Effects\r\n- Images\r\n- Textures\r\n- 3D Models\r\n- and more...\r\n\r\nIf you've organized all your assets to share a single root folder or drive then this asset is for you.\r\n\r\nSelect your root folder, create search categories, associate file extension types with those categories and then Search!\r\n\r\nWant to import your searched for asset?\r\nJust click the import button and select where in your project you want to have it imported to.\r\n\r\nNot sure if it's the correct file you're searching for?\r\nHit the preview button and confirm it for yourself!",
      price: '$15',
      linkTitle:'Unity Asset Store',
      link: 'https://assetstore.unity.com/packages/slug/276299',
      banner: '../../../assets/images/products/ddse/banner.png'
    },
    {
      id: 'short-stories-vr',
      name: 'Short Stories VR',
      description: "Step into immersive short-form experiences in Short Stories VR, where fantasy and science fiction collide in captivating 5 to 30-minute adventures.",
      price: '$4.99',
      linkTitle:'Meta Store',
      link: 'https://www.meta.com/experiences/8433563860028775',
      banner: '../../../assets/images/products/shortstories/Logo.png'
    }
  ];
  currentProduct:ProductModel;
  routeSub:Subscription;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(
      (params:Params)=>
      {
        this.products.forEach(element => {
          if(element.id === params['id'])
          {
            this.currentProduct = element;
            return;
          }
        });
      }
    );
  }

}
