import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationBarComponent } from './shared-components/navigation-bar/navigation-bar.component';
import { SiteFooterComponent } from './shared-components/site-footer/site-footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ProductTemplateComponent } from './pages/product-template/product-template.component';
import { GamesDropdownComponent } from './shared-components/navigation-bar/games-dropdown/games-dropdown.component';
import { ToolsDropdownComponent } from './shared-components/navigation-bar/tools-dropdown/tools-dropdown.component';
import { PrivacyPolicyComponent } from './pages/privacy-policies/privacy-policy/privacy-policy.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    SiteFooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ProductTemplateComponent,
    GamesDropdownComponent,
    ToolsDropdownComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
