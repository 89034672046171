import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: [
    '../../app.component.scss',
    './navigation-bar.component.scss'
  ]
})
export class NavigationBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}