import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-short-stories-pp',
  templateUrl: './short-stories-pp.component.html',
  styleUrls: [
    '../../../app.component.scss',
    './short-stories-pp.component.scss']
})
export class ShortStoriesPpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
