<div class="textPage">
    <div class="row info-box">
        <div class="col-md-12 section">
            <div class="p-3">
                <h2>Dark Dungeon Studio proves the following range of services:</h2>
                <div class="row">
                    <ul>
                        <li>Create 3D or 2D prototypes</li>
                        <li>Create 3D or 2D games</li>
                        <li>Co-development on your projects</li>
                        <li>Quality assurance testing on your games</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>