import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyPolicyComponent } from './pages/privacy-policies/privacy-policy/privacy-policy.component';
import { ProductTemplateComponent } from './pages/product-template/product-template.component';
import { WorkComponent } from './pages/work/work.component';
import { ShortStoriesPpComponent } from './pages/privacy-policies/short-stories-pp/short-stories-pp.component';

const routes: Routes = [
  { path:'', redirectTo:'/home', pathMatch: 'full' },
  { path:'home', component:HomeComponent },
  { path:'about', component:AboutComponent },
  { path:'contact', component:ContactComponent },
  { path:'games/:id', component:ProductTemplateComponent },
  { path:'tools/:id', component:ProductTemplateComponent },
  { path:'privacy-policy', component:PrivacyPolicyComponent },
  { path:'short-stories-pp', component:ShortStoriesPpComponent },
  { path: 'work', component:WorkComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
