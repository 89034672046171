<div class="textPage">
    <div class="row info-box">
        <div class="col-md-12 section">
            <div class="p-3">
                <!--Left column-->
                <h2>ABOUT</h2>
                <h3>The Studio</h3>
                <p>
                    Dark Dungeon Studio is a South African game development studio founded in 2019 by James and Rachael Joubert. We are dedicated to creating challenging and competitive fantasy and sci-fi games aimed at the e-sports community. As we embark on this exciting journey, we invite you to join us in bringing our imaginative and competitive worlds to life!
                </p>
            </div>
        </div>
    </div>
    <div class="row info-box">
        <div class="col-md-12 section">
            <hr>
            <div class="p-3">
                <h3>Who are we?</h3>
            </div>
        </div>
    </div>
    <div class="row info-box">
        <div class="col-md-4 section">
            <div class="p-3">
                <img src="../../../assets/images/James.png" class="img-circle" alt="Profile">
            </div>
        </div>
        <div class="col-md-6 section">
            <div class="p-3">
                <h4>JAMES JOUBERT</h4>
                <h5>Founder & CEO</h5>
            </div>
        </div>
    </div>
    <div class="row info-box">
        <div class="col-md-4 section">
            <div class="p-3">
                <img src="../../../assets/images/Rachael.png" class="img-circle" alt="Profile">
            </div>
        </div>
        <div class="col-md-6 section">
            <div class="p-3">
                <h4>RACHAEL JOUBERT</h4>
                <h5>Co-Founder & CCO</h5>
            </div>
        </div>
    </div>
</div>