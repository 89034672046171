<div class="textPage">
    <div class="row info-box">
        <div class="col-md-12 section">
            <div class="p-3">
                <h2>{{currentProduct.name}}</h2>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-8 offset-md-2">
        <div class="d-flex justify-content-center">
            <img src="{{currentProduct.banner}}" alt="{{currentProduct.name}}" class="banner-image">
        </div>
    </div>
</div>
<div class="textPage">
    <div class="row info-box">
        <div class="col-md-12 section">
            <div class="p-3">         
                <h4>Summary</h4>   
                <p class="formatted">
                    {{currentProduct.description}}
                </p>
                <hr>
                <div class="row">
                    <div class="col-md-2">
                        <b>Price</b>:
                    </div>
                    <div class="col-md-10">
                        {{currentProduct.price}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <b>{{currentProduct.linkTitle}}</b>:
                    </div>
                    <div class="col-md-10">
                        <a href="{{currentProduct.link}}" target="_blank">{{currentProduct.link}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>